'use client'

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import * as Sentry from '@sentry/nextjs'
import NextLink from 'next/link'
import { Box, Button, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useLang from 'translations'

export default function Error ({ error, reset }) {
  const { t } = useLang()
  const theme = useTheme()
  // const mobileDevice = useMediaQuery({ minWidth: 600 })

  useEffect(() => {
    console.error('>> errorComponent', error)
    // @TODO to enable when next 14 works fine
    // Sentry.captureUnderscoreErrorException(error)
  }, [error])

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant="h4"
          // variant={mobileDevice ? 'h4' : 'h1'}
        >
          500: {t('errors.500')}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle2">
          {t('errors.500')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Box
            alt="Under development"
            component="img"
            src={`/s/error/error500_${theme.palette.mode}.svg`}
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <NextLink href="/" passHref>
            <Button component="button" variant="outlined">{t('general.back')}</Button>
          </NextLink>
        </Box>
      </Container>
    </Box>
  )
}

Error.propTypes = {
  error: PropTypes.object,
  reset: PropTypes.func
}
